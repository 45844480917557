import React, { useCallback, useEffect, useState } from 'react';
import { GridColDef, GridFilterItem, GridPaginationModel, GridRenderCellParams, GridSortItem } from '@mui/x-data-grid-pro';
import { useAppDispatch } from '../../store';
import { getPostings } from '../../store/slices/postingsSlice';
import { ToastContainer } from 'react-toastify';
import { Tooltip } from '@mui/material';
import { SharedTable } from '../../components/sharedTable';
import { selectIsPostingsLoading, selectPostings, selectTotalRowPostings } from '../../store/selectors/postingSelector';
import { useSelector } from 'react-redux';
import { getStoredItem, storeItem } from '../../services/storageService';
import { getStateSnapshotFromLocalStorage } from '../../utils/state';
import { useLocation } from 'react-router-dom';
import { RedirectCell } from '../shared';

const defaultFilter: GridFilterItem[] = [];
const defaultSorting: GridSortItem[] = [{ field: 'id', sort: 'desc' }];
const defaultPagination: Partial<GridPaginationModel> = { page: 0, pageSize: 25 };
const hiddenFiltering: GridFilterItem[] = [
  {
    id: 'cargoTypeNotSendback',
    field: 'cargo_type',
    operator: 'not',
    value: 'sendback',
  },
];

export const PostingsToSendPage: React.FC = () => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);

  // from entity
  const fromEntityType = searchParams.get('entity');
  const fromEntityId = searchParams.get('id');

  let tableName = 'postingsToSend' + (fromEntityType ?? '') + (fromEntityId ?? '');

  let fromEntityFilter: GridFilterItem | null = null;

  if (fromEntityType != null && fromEntityId != null) {
    tableName += '-from-' + fromEntityType + '-' + fromEntityId + '-remove-after-' + Date.now().toString().slice(0, 6);

    fromEntityFilter = {
      id: `from_${fromEntityType}`,
      field: `${fromEntityType}_id`,
      operator: '=',
      value: parseInt(fromEntityId, 10),
    };
  }

  const dispatch = useAppDispatch();

  const postings = useSelector(selectPostings);
  const totalRows = useSelector(selectTotalRowPostings);
  const isPostingsLoading = useSelector(selectIsPostingsLoading);

  const [isPaginationChange, setIsPaginationChange] = useState(false);
  const [isDataNededToBeFetched, setIsDataNededToBeFetched] = useState(true);

  const [selectedPostingIds, setSelectedPostingIds] = useState<number[]>([]);

  const aerodarCargoTypeTitlePair = {
    aero_general: 'Общий',
    aero_dangerous: 'Опасный',
    car: 'Автомобиль',
  };

  const columns: GridColDef[] = [
    {
      field: 'id',
      headerName: 'ID',
      type: 'number',
      filterable: fromEntityType ? true : false,
    },
    {
      field: 'cargo_type',
      headerName: 'Тип перевозки',
      type: 'singleSelect',
      valueOptions: Object.entries(aerodarCargoTypeTitlePair).map((entry) => ({
        label: entry[1],
        value: entry[0],
      })),
    },
    {
      field: 'ozon_destination_point_name',
      headerName: 'Место назначение',
      type: 'string',
    },
    {
      field: 'ozon_destination_point_region',
      headerName: 'Регион назначения',
      type: 'string',
    },
    {
      field: 'ozon_destination_point_city',
      headerName: 'Город назначения',
      type: 'string',
    },
    {
      field: 'ozon_destination_point_settlement',
      headerName: 'Населенный пункт назначения',
      type: 'string',
    },
    {
      field: 'ozon_destination_point_index',
      headerName: 'Индекс назначения',
      type: 'string',
    },
    {
      field: 'ozon_return_point_name',
      headerName: 'Место возврата',
      type: 'string',
    },
    {
      field: 'ozon_exemplars',
      headerName: 'Состав',
      valueGetter: (_, row) =>
        row.ozon_exemplars
          ?.map((o: { name: string }) => o.name)
          .slice(0, 2)
          .join('\n') ?? 'Не указан',
      renderCell: (params: GridRenderCellParams) => (
        <Tooltip title={params.row.ozon_exemplars?.map((o: { name: string }) => o.name).join('\n') ?? ''}>
          <span>{params.value}</span>
        </Tooltip>
      ),
    },
    {
      field: 'danger_class',
      headerName: 'Наш ОГ',
      type: 'number',
    },
    {
      field: 'ozon_danger_class',
      headerName: 'Ozon ОГ',
      type: 'number',
    },
    {
      field: 'ozon_inbound_id',
      headerName: 'Входящая поставка',
      type: 'number',
      renderCell: (params) => <RedirectCell url={`/inbounds?id=${params.row.ozon_inbound_id}`} value={params.value} />,
    },
    {
      field: 'outbound_id',
      headerName: 'Исходящая поставка',
      type: 'number',
      renderCell: (params) => <RedirectCell url={`/outbounds?id=${params.row.outbound_id}`} value={params.value} />,
      filterable: fromEntityType === 'outbound' ? false : true,
      sortable: fromEntityType === 'outbound' ? false : true,
    },
    {
      field: 'last_scan_at',
      headerName: 'Последнее сканирование',
      type: 'dateTime',
    },
  ];

  const handleSelectionChange = useCallback(
    (pageSelectedRowIds: number[]) => {
      if (isPaginationChange) {
        setIsPaginationChange(false);
        return;
      }

      setSelectedPostingIds(pageSelectedRowIds);
      storeItem('selectedOn' + tableName, pageSelectedRowIds);
    },
    [isPaginationChange]
  );

  const handlePaginationChange = useCallback(() => {
    setIsDataNededToBeFetched(true);
    setIsPaginationChange(true);
  }, []);

  const dispatchGetPostings = useCallback(async () => {
    const snapshotState = await getStateSnapshotFromLocalStorage(tableName);

    let filters = snapshotState?.filter?.filterModel?.items ?? defaultFilter;

    if (fromEntityFilter != null) {
      filters = [...filters, fromEntityFilter];
    }

    const resultAction = await dispatch(
      getPostings({
        filtering: [filters, hiddenFiltering].flat(),
        sorting: snapshotState?.sorting?.sortModel ?? defaultSorting,
        ...(snapshotState?.pagination?.paginationModel ?? defaultPagination),
      })
    );

    if (getPostings.fulfilled.match(resultAction)) {
      setIsDataNededToBeFetched(false);
      const storedSelectedRowIds = getStoredItem<number[]>('selectedOn' + tableName);
      if (storedSelectedRowIds) {
        setSelectedPostingIds(storedSelectedRowIds);
      }
    }
  }, [defaultFilter, defaultPagination, defaultSorting, dispatch]);

  useEffect(() => {
    if (isDataNededToBeFetched) {
      dispatchGetPostings();
    }
  }, [isDataNededToBeFetched, defaultFilter, defaultPagination, defaultSorting]);

  const handleDataQueryChange = useCallback(() => {
    setIsDataNededToBeFetched(true);
  }, []);

  return (
    <div>
      <ToastContainer />

      <SharedTable
        columns={columns}
        loading={isPostingsLoading}
        onFilterModelChange={handleDataQueryChange}
        onPaginationChange={handlePaginationChange}
        onSelectionChange={handleSelectionChange}
        onSortModelChange={handleDataQueryChange}
        rowCount={totalRows}
        rows={postings}
        selectedRowIds={selectedPostingIds}
        tableName={tableName}
      />
    </div>
  );
};
