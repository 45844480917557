import React, { useCallback, useEffect, useState } from 'react';
import { Box, Button, CircularProgress, IconButton, Input, TextField, Tooltip } from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';
import DownloadIcon from '@mui/icons-material/Download';
import { toast, ToastContainer } from 'react-toastify';
import { GridColDef, GridFilterItem, GridPaginationModel, GridSortItem } from '@mui/x-data-grid-pro';
import { SharedTable } from '../../components/sharedTable';
import { useAppDispatch } from '../../store';
import { useSelector } from 'react-redux';
import {
  selectOutbounds,
  selectTotalRowOutbounds,
  selectWaitOutboundMesure,
  selectWaitOutboundPack,
  selectWaitOutboundSend,
  selectWaitOutboundSetCargoDate,
  selectWaitOutboundsTableFetch,
} from '../../store/selectors/outboundsSelector';
import {
  getOutbounds,
  measureOutbounds,
  sendOutbounds,
  packOutbounds,
  cargoDateSetOutbounds,
  regenerateActs,
} from '../../store/slices/outboundsSlice';
import { getStoredItem, storeItem } from '../../services/storageService';
import { CargoType } from '../../models/Outbound';
import { getStateSnapshotFromLocalStorage } from '../../utils/state';
import { useLocation } from 'react-router-dom';
import { RedirectCell } from '../shared';
import api from '../../services/apiClient';
import { IOzonLogItem } from '../../models/OzonLogItem';

const defaultFilter: GridFilterItem[] = [];
const defaultSorting: GridSortItem[] = [{ field: 'id', sort: 'desc' }];
const defaultPagination: Partial<GridPaginationModel> = { page: 0, pageSize: 25 };
const hiddenFiltering: GridFilterItem[] = [
  {
    id: 'cargoTypeNotSendback',
    field: 'cargo_type',
    operator: 'not',
    value: 'sendback',
  },
];

export const OutboundsPage: React.FC = () => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);

  const entityId = searchParams.get('id');

  const filterById = entityId
    ? [
        {
          id: 'by_id',
          field: 'id',
          operator: '=',
          value: parseInt(entityId, 10),
        },
      ]
    : null;

  const tableName = 'outbounds' + (entityId ? 'OneRow' : '');

  const dispatch = useAppDispatch();

  const outbounds = useSelector(selectOutbounds);
  const isLoading = useSelector(selectWaitOutboundsTableFetch);
  const waitMeasure = useSelector(selectWaitOutboundMesure);
  const waitPack = useSelector(selectWaitOutboundPack);
  const waitSend = useSelector(selectWaitOutboundSend);
  const waitSetCargoDate = useSelector(selectWaitOutboundSetCargoDate);
  const totalRows = useSelector(selectTotalRowOutbounds);

  const [isDataNededToBeFetched, setIsDataNededToBeFetched] = useState(true);
  const [isPaginationChange, setIsPaginationChange] = useState(false);

  const [selectedOutboundIds, setSelectedOutboundIds] = useState<number[]>([]);

  const [volume, setVolume] = useState<string>('');
  const [weight, setWeight] = useState<string>('');
  const [cargoDate, setCargoDate] = useState<string>();

  const cargoTypeTitlePair: { [key in CargoType]: string } = {
    aero_general: 'Общий',
    aero_dangerous: 'Опасный',
    sendback: 'Возврат',
    car: 'Автомобиль',
  };

  let columns: GridColDef[] = [
    {
      field: 'id',
      headerName: 'ID',
      type: 'number',
      hideable: false,
    },
    {
      field: 'created_at',
      headerName: 'Создано',
      type: 'dateTime',
    },
    {
      field: 'updated_at',
      headerName: 'Обновлено',
      type: 'dateTime',
    },
    {
      field: 'inbound_id',
      headerName: 'Входящая поставка',
      type: 'number',
      renderCell: (params) => <RedirectCell url={`/inbounds?id=${params.row.inbound_id}`} value={params.value} />,
    },
    {
      field: 'cargo_type',
      headerName: 'Способ отправки',
      type: 'singleSelect',
      valueOptions: Object.entries(cargoTypeTitlePair).map((entry) => ({
        label: entry[1],
        value: entry[0],
      })),
    },
    {
      field: 'destination_point_name',
      headerName: 'Место назначения',
      type: 'string',
    },
    {
      field: 'destination_point_region',
      headerName: 'Регион назначения',
      type: 'string',
    },
    {
      field: 'destination_point_city',
      headerName: 'Город назначения',
      type: 'string',
    },
    {
      field: 'destination_point_settlement',
      headerName: 'Поселок назначения',
      type: 'string',
    },
    {
      field: 'destination_point_index',
      headerName: 'Индекс назначения',
      type: 'number',
    },
    {
      field: 'postings_count',
      headerName: 'Отправлений',
      type: 'number',
      renderCell: (params) => <RedirectCell url={`/postings-to-send?entity=outbound&id=${params.row.id}`} value={params.value} />,
    },
    {
      field: 'total_weight',
      headerName: 'Вес',
      type: 'number',
      valueGetter: (value) => Math.ceil((value / 1000) * 1000) / 1000,
      description: 'Вес в килограммах',
    },
    {
      field: 'total_volumetric_weight',
      headerName: 'Об.вес',
      type: 'number',
      valueGetter: (value) => Math.ceil(value * 1000) / 1000,
      description: 'Объемный вес в килограммах, расчитывается как объем в м3 умноженый на 167',
    },
    {
      field: 'packed_at',
      headerName: 'Упаковано',
      type: 'dateTime',
    },
    {
      field: 'sent_at',
      headerName: 'Отправлено',
      type: 'dateTime',
    },
    {
      field: 'shipped_cargo_at',
      headerName: 'Вылетело ГВС',
      type: 'dateTime',
    },
    {
      field: 'ozon_supply_id',
      headerName: 'Supply ID',
      type: 'number',
    },
    {
      field: 'ozon_supply_act_number',
      headerName: 'Номер акта',
      renderCell: (params) => {
        if (params.value !== null) {
          return (
            <div style={{ display: 'flex', justifyContent: 'center', height: '100%', alignItems: 'center' }}>
              {params.value}
              <Tooltip title={<span style={{ whiteSpace: 'pre-wrap' }}>Скачать акт</span>}>
                <IconButton
                  color="primary"
                  aria-label="download-act"
                  size="small"
                  style={{ marginLeft: 2, background: 'none' }}
                  onClick={() => handleDownloadOneAct(params.row.id)}
                >
                  <DownloadIcon />
                </IconButton>
              </Tooltip>
            </div>
          );
        }
        return null;
      },
    },
    {
      field: 'ozon_supply_act_date',
      headerName: 'Дата акта',
      type: 'dateTime',
    },
    {
      field: 'delivered_at',
      headerName: 'Доставлено',
      type: 'dateTime',
    },
    {
      field: 'ozon_logs',
      headerName: 'Логи Озон',
      type: 'string',
      renderCell: (params) => {
        if (params.value !== null) {
          return (
            <div style={{ display: 'flex', justifyContent: 'center', height: '100%' }}>
              <Tooltip
                title={
                  <span style={{ whiteSpace: 'pre-wrap' }}>
                    {params.value.map((log: IOzonLogItem) => `${log.message} (${log.timestamp})`).join('\n')}
                  </span>
                }
              >
                <InfoIcon color="error" style={{ marginLeft: 5, cursor: 'pointer', alignSelf: 'center' }} />
              </Tooltip>
            </div>
          );
        }
        return null;
      },
    },
  ];

  if (filterById) {
    columns = columns.map((x) => {
      x.filterable = false;
      x.sortable = false;
      return x;
    });
  }

  const handleSelectionChange = useCallback(
    (pageSelectedRowIds: number[]) => {
      if (isPaginationChange) {
        setIsPaginationChange(false);
        return;
      }

      setSelectedOutboundIds(pageSelectedRowIds);
      storeItem('selectedOn' + tableName, pageSelectedRowIds);

      setVolume('');
      setWeight('');
      setCargoDate('');
    },
    [outbounds, selectedOutboundIds, isPaginationChange]
  );

  const handlePaginationChange = useCallback(() => {
    setIsPaginationChange(true);
    setIsDataNededToBeFetched(true);
  }, [weight, volume]);

  const handleWeightChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    let newValue = event.target.value;
    const regex = /^\d*(?:[.,]\d*)?$/;
    if (!regex.test(newValue)) {
      return;
    }
    if (newValue.includes(',')) {
      newValue = newValue.replace(',', '.');
    }
    setWeight(newValue);
  };

  const handleVolumeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    let newValue = event.target.value;
    const regex = /^\d*(?:[.,]\d*)?$/;
    if (!regex.test(newValue)) {
      return;
    }
    if (newValue.includes(',')) {
      newValue = newValue.replace(',', '.');
    }
    setVolume(newValue);
  };

  const handleCargoDateChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCargoDate(event.target.value);
  };

  const handleCargoSend = useCallback(async () => {
    const resultAction = await dispatch(
      cargoDateSetOutbounds({
        outbound_ids: selectedOutboundIds,
        date: cargoDate,
      })
    );

    if (cargoDateSetOutbounds.fulfilled.match(resultAction)) {
      toast.success(resultAction.payload['message:']);
    }
  }, [dispatch, selectedOutboundIds, cargoDate]);

  const handleMeasure = useCallback(async () => {
    const outbounds = selectedOutboundIds.map((id) => ({ id }));
    const resultAction = await dispatch(
      measureOutbounds({
        outbounds: outbounds,
        volume: Number(volume),
        weight: Number(weight),
      })
    );

    if (measureOutbounds.fulfilled.match(resultAction)) {
      toast.success(resultAction.payload['message:']);
    }
  }, [dispatch, selectedOutboundIds, weight, volume]);

  const handlePack = useCallback(async () => {
    const outbounds = selectedOutboundIds.map((id) => ({ id }));
    const resultAction = await dispatch(packOutbounds(outbounds));

    if (packOutbounds.fulfilled.match(resultAction)) {
      toast.success(resultAction.payload['message:']);
    }
  }, [dispatch, selectedOutboundIds]);

  const handleSend = useCallback(async () => {
    const wrappedIds = selectedOutboundIds.map((id) => ({ id }));
    const resultAction = await dispatch(sendOutbounds(wrappedIds));

    if (sendOutbounds.fulfilled.match(resultAction)) {
      toast.success('Успешно обновлены!');
      dispatchGetOutbounds();
    }
  }, [dispatch, selectedOutboundIds, weight, volume]);

  const dispatchGetOutbounds = useCallback(async () => {
    const snapshotState = await getStateSnapshotFromLocalStorage(tableName);
    const resultAction = await dispatch(
      getOutbounds({
        filtering: filterById ? filterById : [snapshotState?.filter?.filterModel?.items ?? defaultFilter, hiddenFiltering].flat(),
        sorting: snapshotState?.sorting?.sortModel ?? defaultSorting,
        ...(snapshotState?.pagination?.paginationModel ?? defaultPagination),
      })
    );

    if (getOutbounds.fulfilled.match(resultAction)) {
      setIsDataNededToBeFetched(false);
      const storedSelectedRowIds = getStoredItem<number[]>('selectedOn' + tableName);
      if (storedSelectedRowIds) {
        setSelectedOutboundIds(storedSelectedRowIds);
        setVolume(getStoredItem<string>('selectedOutboundVolumes') ?? '');
        setWeight(getStoredItem<string>('selectedOutboundWeights') ?? '');
        setCargoDate('');
      }
    }
  }, [isDataNededToBeFetched]);

  const handleDataQueryChange = useCallback(() => {
    setIsDataNededToBeFetched(true);
  }, []);

  useEffect(() => {
    if (isDataNededToBeFetched) {
      dispatchGetOutbounds();
    }
  }, [isDataNededToBeFetched]);

  const handleDownloadActs = async () => {
    try {
      const response = await api.post('/outbounds/docs', selectedOutboundIds, { responseType: 'blob' });
      const blob = new Blob([response.data], { type: response.data.type });
      const url = URL.createObjectURL(blob);

      // Create a link and trigger the download
      const link = document.createElement('a');
      link.href = url;
      link.download = 'outbounds_docs.zip';
      link.click();

      // Clean up the URL object
      URL.revokeObjectURL(url);
    } catch (error) {
      toast.error('Error occurred while downloading documents');
    }
  };

  const handleDownloadOneAct = async (id: number) => {
    try {
      const response = await api.post('/outbounds/docs', [id], { responseType: 'blob' });
      const blob = new Blob([response.data], { type: response.data.type });
      const url = URL.createObjectURL(blob);

      const link = document.createElement('a');
      link.href = url;
      link.download = `outbound_${id}_docs.zip`;
      link.click();

      URL.revokeObjectURL(url);
    } catch (error) {
      toast.error('Error occurred while downloading document');
    }
  };

  const handleRegenerateActs = async () => {
    const resultAction = await dispatch(regenerateActs(selectedOutboundIds));

    if (regenerateActs.fulfilled.match(resultAction)) {
      toast.success('Успешно отправлен запрос на обновление!');
      dispatchGetOutbounds();
    } else if (regenerateActs.rejected.match(resultAction)) {
      toast.error('Ошибка обновления актов!');
    }
  };

  const customToolBarList = selectedOutboundIds.length > 0 && (
    <>
      <Button variant="outlined" color="primary" size="small" onClick={handleDownloadActs}>
        Загрузить акты
      </Button>
      <Button variant="outlined" color="primary" size="small" onClick={handleRegenerateActs}>
        Обновить акты
      </Button>
    </>
  );

  return (
    <div>
      <ToastContainer />
      <Box sx={{ display: 'flex', gap: '12px' }}>
        <Button
          variant="contained"
          color="info"
          onClick={handlePack}
          disabled={waitPack || selectedOutboundIds.length < 1}
          startIcon={waitPack ? <CircularProgress size={16} /> : null}
        >
          {waitPack ? 'Обновление...' : 'Упаковать'}
        </Button>
        <Button
          variant="contained"
          color="info"
          onClick={handleSend}
          disabled={waitSend || selectedOutboundIds.length < 1}
          startIcon={waitSend ? <CircularProgress size={16} /> : null}
        >
          {waitSend ? 'Запись отправки...' : 'Записать отправку'}
        </Button>
      </Box>
      <br />

      <Box sx={{ display: 'flex', gap: '12px' }}>
        <TextField label="Вес, кг" name="weight" value={weight} onChange={handleWeightChange} required />
        <TextField label="Объем, м3" name="volume" value={volume} onChange={handleVolumeChange} required />
        <Button
          variant="contained"
          color="info"
          onClick={handleMeasure}
          disabled={waitMeasure || selectedOutboundIds.length < 1}
          startIcon={waitMeasure ? <CircularProgress size={16} /> : null}
        >
          {waitMeasure ? 'Обновление...' : 'Отправить замеры'}
        </Button>
      </Box>
      <br />

      <Box sx={{ display: 'flex', gap: '12px' }}>
        <Input type="date" placeholder="Дата вылета ГВС" value={cargoDate} onChange={handleCargoDateChange} />
        <Button
          variant="contained"
          color="info"
          onClick={handleCargoSend}
          disabled={waitSetCargoDate || !cargoDate || new Date(cargoDate) < new Date('2024-01-01')}
          startIcon={waitSetCargoDate ? <CircularProgress size={16} /> : null}
        >
          {waitSetCargoDate ? 'Обновление...' : 'Дата вылета ГВС'}
        </Button>
      </Box>
      <br />
      <SharedTable
        columns={columns}
        loading={isLoading}
        onFilterModelChange={handleDataQueryChange}
        onPaginationChange={handlePaginationChange}
        onSelectionChange={handleSelectionChange}
        onSortModelChange={handleDataQueryChange}
        rowCount={totalRows}
        rows={outbounds}
        selectedRowIds={selectedOutboundIds}
        tableName={tableName}
        customTollbarList={customToolBarList}
      />
    </div>
  );
};
