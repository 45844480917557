import React, { useCallback, useEffect, useState } from 'react';
import { Box, Button, CircularProgress, TextField, Tooltip } from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';
import { toast, ToastContainer } from 'react-toastify';
import { GridColDef, GridFilterItem, GridPaginationModel, GridSortItem } from '@mui/x-data-grid-pro';
import { SharedTable } from '../../components/sharedTable';
import { useAppDispatch } from '../../store';
import { useSelector } from 'react-redux';
import { getStoredItem, storeItem } from '../../services/storageService';
import { getStateSnapshotFromLocalStorage } from '../../utils/state';
import { useLocation } from 'react-router-dom';
import { IOzonLogItem } from '../../models/OzonLogItem';
import {
  selectFromGanShipments,
  selectTotalRowsFromGanShipments,
  selectWaitFromGanShipmentsPacking,
  selectWaitFromGanShipmentsSending,
  selectWaitFromGanShipmentsTableFetch,
} from '../../store/selectors/fromGanShipmentsSelector';
import {
  FromGanShipmentsServerErrorResponseType,
  getFromGanShipments,
  packFromGanShipments,
  sendFromGanShipments,
} from '../../store/slices/fromGanShipmentsSlice';
import { RedirectCell } from '../shared';

const defaultFilter: GridFilterItem[] = [];
const defaultSorting: GridSortItem[] = [{ field: 'id', sort: 'desc' }];
const defaultPagination: Partial<GridPaginationModel> = { page: 0, pageSize: 25 };

export const FromGanShipmentsPage: React.FC = () => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);

  const entityId = searchParams.get('id');
  const entityShipmentId = searchParams.get('shipment');

  const filterById = entityId
    ? [
        {
          id: 'by_id',
          field: 'id',
          operator: '=',
          value: parseInt(entityId, 10),
        },
      ]
    : null;

  const filterByShipmentId = entityShipmentId
    ? [
        {
          id: 'by_shipment_id',
          field: 'shipments',
          operator: 'isArrayColumnContains',
          value: parseInt(entityShipmentId, 10),
        },
      ]
    : null;

  const tableName = 'fromGanShipments' + (entityId ? 'OneRow' : '');

  const dispatch = useAppDispatch();

  const fromGanShipments = useSelector(selectFromGanShipments);
  const isLoading = useSelector(selectWaitFromGanShipmentsTableFetch);
  const waitPacking = useSelector(selectWaitFromGanShipmentsPacking);
  const waitSending = useSelector(selectWaitFromGanShipmentsSending);
  const totalRows = useSelector(selectTotalRowsFromGanShipments);

  const [isDataNededToBeFetched, setIsDataNededToBeFetched] = useState(true);
  const [isPaginationChange, setIsPaginationChange] = useState(false);

  const [selectedFromGanShipmentsIds, setSelectedFromGanShipmentsIds] = useState<number[]>([]);

  const [ganWeight, setGanWeight] = useState<string>('');
  const [ganNumber, setGanNumber] = useState<string>('');
  const [tmsNumber, setTmsNumber] = useState<number>(0);

  let columns: GridColDef[] = [
    {
      field: 'id',
      headerName: 'ID',
      type: 'number',
      hideable: false,
    },
    {
      field: 'number',
      headerName: 'Номер ГАН',
      type: 'string',
    },
    {
      field: 'weight',
      headerName: 'Вес ГАН',
      type: 'number',
    },
    {
      field: 'tms_order_id',
      headerName: 'Номер ТМС',
      type: 'number',
    },
    {
      field: 'shipments_count',
      headerName: 'ПВЗ Рейсы',
      type: 'number',
      renderCell: (params) => {
        if (params.value > 0) {
          return <RedirectCell url={`/from-pickup-shipments?bill=${params.row.id}`} value={params.value} />;
        }
      },
    },
    {
      field: 'created_at',
      headerName: 'Создана',
      type: 'dateTime',
    },
    {
      field: 'updated_at',
      headerName: 'Обновлена',
      type: 'dateTime',
    },
    {
      field: 'packed_at',
      headerName: 'Упакована',
      type: 'dateTime',
    },
    {
      field: 'sent_at',
      headerName: 'Отправлена',
      type: 'dateTime',
    },
    {
      field: 'received_count',
      headerName: 'Принято',
      type: 'number',
      renderCell: (params) => {
        if (params.value > 0) {
          return <RedirectCell url={`/from-pickup-cargo-places?reverse_airwaybill_id=${params.row.id}`} value={params.value} />;
        }
      },
    },
    {
      field: 'ozon_logs',
      headerName: 'Логи Озон',
      type: 'string',
      renderCell: (params) => {
        if (params.value !== null) {
          return (
            <div style={{ display: 'flex', justifyContent: 'center', height: '100%' }}>
              <Tooltip
                title={
                  <span style={{ whiteSpace: 'pre-wrap' }}>
                    {params.value.map((log: IOzonLogItem) => `${log.message} (${log.timestamp})`).join('\n')}
                  </span>
                }
              >
                <InfoIcon color="error" style={{ marginLeft: 5, cursor: 'pointer', alignSelf: 'center' }} />
              </Tooltip>
            </div>
          );
        }
        return null;
      },
    },
  ];

  if (filterById) {
    columns = columns.map((x) => {
      x.filterable = false;
      x.sortable = false;
      return x;
    });
  }

  const handleSelectionChange = useCallback(
    (pageSelectedRowIds: number[]) => {
      if (isPaginationChange) {
        setIsPaginationChange(false);
        return;
      }

      setSelectedFromGanShipmentsIds(pageSelectedRowIds);
      storeItem('selectedOn' + tableName, pageSelectedRowIds);

      setGanNumber('');
      setGanWeight('');
      setTmsNumber(0);
    },
    [fromGanShipments, selectedFromGanShipmentsIds, isPaginationChange]
  );

  const handlePaginationChange = useCallback(() => {
    setIsPaginationChange(true);
    setIsDataNededToBeFetched(true);
  }, [ganWeight, ganNumber]);

  const handleGanWeightChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    let newValue = event.target.value;
    const regex = /^\d*(?:[.,]\d*)?$/;
    if (!regex.test(newValue)) {
      return;
    }
    if (newValue.includes(',')) {
      newValue = newValue.replace(',', '.');
    }
    setGanWeight(newValue);
  };

  const handleGanNumberChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setGanNumber(event.target.value);
  };

  const handleTmsNumberChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setTmsNumber(Number(event.target.value));
  };

  const handlePack = useCallback(async () => {
    const currentFromGanShipmentId = selectedFromGanShipmentsIds[0];
    const fromGanShipmentToBePacked = { id: currentFromGanShipmentId, number: ganNumber, weight: Number(ganWeight) };
    const resultAction = await dispatch(packFromGanShipments(fromGanShipmentToBePacked));

    if (packFromGanShipments.fulfilled.match(resultAction)) {
      toast.success(resultAction.payload.message);
      dispatchGetFromGanShipments();
    } else if (packFromGanShipments.rejected.match(resultAction)) {
      const resultError = resultAction.payload as FromGanShipmentsServerErrorResponseType;
      toast.error(resultError.message);
    }
  }, [dispatch, selectedFromGanShipmentsIds, ganWeight, ganNumber]);

  const handleSend = useCallback(async () => {
    const dataToBeSended = { tms_order_id: tmsNumber, airwaybills_ids: selectedFromGanShipmentsIds };
    const resultAction = await dispatch(sendFromGanShipments(dataToBeSended));
    if (sendFromGanShipments.fulfilled.match(resultAction)) {
      const result = resultAction.payload;
      toast(
        <div>
          <div>
            <b>{result.message}</b>
          </div>
          <br />
          <div>TMS ID записан ({result.updated_count})</div>
          {result.details.updated.map((item: any) => (
            <div key={item}>
              <i>- {item}</i>
            </div>
          ))}
          <br />
          <div>Ранее записаны ({result.already_registered_count})</div>
          {result.details.already_registered.map((item: any) => (
            <div key={item.id}>
              <i>- {item.id}</i>
            </div>
          ))}
        </div>,
        { autoClose: 10000 }
      );
      dispatchGetFromGanShipments();
    } else if (sendFromGanShipments.rejected.match(resultAction)) {
      const resultError = resultAction.payload as FromGanShipmentsServerErrorResponseType;
      toast.error(resultError.message);
    }
  }, [dispatch, selectedFromGanShipmentsIds, tmsNumber]);

  const dispatchGetFromGanShipments = useCallback(async () => {
    const snapshotState = await getStateSnapshotFromLocalStorage(tableName);
    const filteringValue = (() => {
      if (filterById) {
        return filterById;
      } else if (filterByShipmentId) {
        return filterByShipmentId;
      } else {
        return [snapshotState?.filter?.filterModel?.items ?? defaultFilter].flat();
      }
    })();
    const resultAction = await dispatch(
      getFromGanShipments({
        filtering: filteringValue,
        sorting: snapshotState?.sorting?.sortModel ?? defaultSorting,
        ...(snapshotState?.pagination?.paginationModel ?? defaultPagination),
      })
    );

    if (getFromGanShipments.fulfilled.match(resultAction)) {
      setIsDataNededToBeFetched(false);
      const storedSelectedRowIds = getStoredItem<number[]>('selectedOn' + tableName);
      if (storedSelectedRowIds) {
        setSelectedFromGanShipmentsIds(storedSelectedRowIds);
        setGanNumber(getStoredItem<string>('selectedFromGanShipmentsGanNumbers') ?? '');
        setGanWeight(getStoredItem<string>('selectedFromGanShipmentsGanWeights') ?? '');
      }
    }
  }, [isDataNededToBeFetched]);

  const handleDataQueryChange = useCallback(() => {
    setIsDataNededToBeFetched(true);
  }, []);

  useEffect(() => {
    if (isDataNededToBeFetched) {
      dispatchGetFromGanShipments();
    }
  }, [isDataNededToBeFetched]);

  return (
    <div>
      <ToastContainer />
      <Box sx={{ display: 'flex', gap: '12px' }}>
        <TextField label="Номер ГАН" name="ganNumber" value={ganNumber} onChange={handleGanNumberChange} />
        <TextField label="Вес ГАН" name="ganWeight" value={ganWeight} onChange={handleGanWeightChange} />
        <Button
          variant="contained"
          color="info"
          onClick={handlePack}
          disabled={waitPacking || selectedFromGanShipmentsIds.length !== 1}
          startIcon={waitPacking ? <CircularProgress size={16} /> : null}
        >
          {waitPacking ? 'Упаковка...' : 'Упаковать'}
        </Button>
      </Box>
      <br />
      <Box sx={{ display: 'flex', gap: '12px' }}>
        <TextField label="Номер заявки TMS" name="tmsNumber" value={tmsNumber} onChange={handleTmsNumberChange} required />
        <Button
          variant="contained"
          color="info"
          onClick={handleSend}
          disabled={waitSending || selectedFromGanShipmentsIds.length < 1}
          startIcon={waitSending ? <CircularProgress size={16} /> : null}
        >
          {waitSending ? 'Фиксация выезда...' : 'Зафиксировать выезд'}
        </Button>
      </Box>
      <br />
      <SharedTable
        columns={columns}
        loading={isLoading}
        onFilterModelChange={handleDataQueryChange}
        onPaginationChange={handlePaginationChange}
        onSelectionChange={handleSelectionChange}
        onSortModelChange={handleDataQueryChange}
        rowCount={totalRows}
        rows={fromGanShipments}
        selectedRowIds={selectedFromGanShipmentsIds}
        tableName={tableName}
      />
    </div>
  );
};
