import React, { useCallback, useEffect, useState } from 'react';
import { GridColDef, GridFilterItem, GridPaginationModel, GridSortItem } from '@mui/x-data-grid-pro';
import { SharedTable } from '../../components/sharedTable';
import { useAppDispatch } from '../../store';
import { useSelector } from 'react-redux';
import { getStoredItem, storeItem } from '../../services/storageService';
import { getStateSnapshotFromLocalStorage } from '../../utils/state';
import { useLocation } from 'react-router-dom';
import {
  selectFromPickupCargoPlaces,
  selectTotalRowFromPickupCargoPlaces,
  selectWaitFromPickupCargoPlacesTableFetch,
} from '../../store/selectors/fromPickupCargoPlacesSelector';
import { getFromPickupCargoPlaces } from '../../store/slices/fromPickupCargoPlacesSlice';
import { RedirectCell } from '../shared';

const defaultFilter: GridFilterItem[] = [];
const defaultSorting: GridSortItem[] = [{ field: 'id', sort: 'desc' }];
const defaultPagination: Partial<GridPaginationModel> = { page: 0, pageSize: 25 };

export const FromPickupCargoPlacesPage: React.FC = () => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);

  const entityId = searchParams.get('id');
  const entityFromPickupShipmentId = searchParams.get('from_pickup_shipment_id');
  const entityReverseAirwaybillId = searchParams.get('reverse_airwaybill_id');

  const filterById = entityId
    ? [
        {
          id: 'by_id',
          field: 'id',
          operator: '=',
          value: parseInt(entityId, 10),
        },
      ]
    : null;

  const filterByFromPickupShipmentId = entityFromPickupShipmentId
    ? [
        {
          id: 'by_from_pickup_shipment_id',
          field: 'from_pickup_shipment_id',
          operator: '=',
          value: parseInt(entityFromPickupShipmentId, 10),
        },
      ]
    : null;

  const filterByReverseAirwaybillId = entityReverseAirwaybillId
    ? [
        {
          id: 'by_reverse_airwaybill_id',
          field: 'reverse_airwaybill_id',
          operator: '=',
          value: parseInt(entityReverseAirwaybillId, 10),
        },
      ]
    : null;

  const tableName = 'fromPickupCargoPlaces' + (entityId ? 'OneRow' : '');

  const dispatch = useAppDispatch();

  const fromPickupCargoPlaces = useSelector(selectFromPickupCargoPlaces);
  const isLoading = useSelector(selectWaitFromPickupCargoPlacesTableFetch);
  const totalRows = useSelector(selectTotalRowFromPickupCargoPlaces);

  const [isDataNededToBeFetched, setIsDataNededToBeFetched] = useState(true);
  const [isPaginationChange, setIsPaginationChange] = useState(false);

  const [selectedFromPickupCargoPlacesIds, setSelectedFromPickupCargoPlacesIds] = useState<number[]>([]);

  let columns: GridColDef[] = [
    {
      field: 'id',
      headerName: 'ID',
      type: 'number',
      hideable: false,
    },
    {
      field: 'barcode',
      headerName: 'Штрих-код',
      type: 'string',
    },
    {
      field: 'from_pickup_shipment_id',
      headerName: 'ID поставки из ПВЗ',
      type: 'number',
      renderCell: (params) => <RedirectCell url={`/from-pickup-shipments?id=${params.row.from_pickup_shipment_id}`} value={params.value} />,
      filterable: filterByFromPickupShipmentId === null ? true : false,
      sortable: filterByFromPickupShipmentId === null ? true : false,
    },
    {
      field: 'reverse_airwaybill_id',
      headerName: 'ID ГАН',
      type: 'number',
      renderCell: (params) => <RedirectCell url={`/from-gan-shipments?id=${params.row.reverse_airwaybill_id}`} value={params.value} />,
      filterable: filterByReverseAirwaybillId === null ? true : false,
      sortable: filterByReverseAirwaybillId === null ? true : false,
    },
    {
      field: 'created_at',
      headerName: 'Создано',
      type: 'dateTime',
    },
    {
      field: 'updated_at',
      headerName: 'Обновлено',
      type: 'dateTime',
    },
    {
      field: 'last_scan_at',
      headerName: 'Последнее сканирование',
      type: 'dateTime',
    },
  ];

  if (filterById) {
    columns = columns.map((x) => {
      x.filterable = false;
      x.sortable = false;
      return x;
    });
  }

  const handleSelectionChange = useCallback(
    (pageSelectedRowIds: number[]) => {
      if (isPaginationChange) {
        setIsPaginationChange(false);
        return;
      }

      setSelectedFromPickupCargoPlacesIds(pageSelectedRowIds);
      storeItem('selectedOn' + tableName, pageSelectedRowIds);
    },
    [fromPickupCargoPlaces, selectedFromPickupCargoPlacesIds, isPaginationChange]
  );

  const handlePaginationChange = useCallback(() => {
    setIsPaginationChange(true);
    setIsDataNededToBeFetched(true);
  }, []);

  const dispatchGetFromPickupCargoPlaces = useCallback(async () => {
    const snapshotState = await getStateSnapshotFromLocalStorage(tableName);
    const filteringValue = (() => {
      if (filterById) {
        return filterById;
      } else if (filterByFromPickupShipmentId) {
        return filterByFromPickupShipmentId;
      } else if (filterByReverseAirwaybillId) {
        return filterByReverseAirwaybillId;
      } else {
        return [snapshotState?.filter?.filterModel?.items ?? defaultFilter].flat();
      }
    })();

    const resultAction = await dispatch(
      getFromPickupCargoPlaces({
        filtering: filteringValue,
        sorting: snapshotState?.sorting?.sortModel ?? defaultSorting,
        ...(snapshotState?.pagination?.paginationModel ?? defaultPagination),
      })
    );

    if (getFromPickupCargoPlaces.fulfilled.match(resultAction)) {
      setIsDataNededToBeFetched(false);
      const storedSelectedRowIds = getStoredItem<number[]>('selectedOn' + tableName);
      if (storedSelectedRowIds) {
        setSelectedFromPickupCargoPlacesIds(storedSelectedRowIds);
      }
    }
  }, [isDataNededToBeFetched]);

  const handleDataQueryChange = useCallback(() => {
    setIsDataNededToBeFetched(true);
  }, []);

  useEffect(() => {
    if (isDataNededToBeFetched) {
      dispatchGetFromPickupCargoPlaces();
    }
  }, [isDataNededToBeFetched]);

  return (
    <div>
      <SharedTable
        columns={columns}
        loading={isLoading}
        onFilterModelChange={handleDataQueryChange}
        onPaginationChange={handlePaginationChange}
        onSelectionChange={handleSelectionChange}
        onSortModelChange={handleDataQueryChange}
        rowCount={totalRows}
        rows={fromPickupCargoPlaces}
        selectedRowIds={selectedFromPickupCargoPlacesIds}
        tableName={tableName}
      />
    </div>
  );
};
